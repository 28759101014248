.body-container {
    display: block;
}

.search-container {
    width: 50%;
    margin: 1vh auto 0vh auto;
}

.search-page-bar-container {
    margin: 5vh 0 2vh 0;
}
.search-bar {
    max-width: 100% !important;
}

.search-input {
    height: 2.5em;
}

.s-button {
    margin: 0.5vh 1vh 3vh 1vh;
    width: 120px;
    color: #000;
    background: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 18px;
    height: 40px;
    /* box-shadow: 3px 3px 1px #ddd; */
    font-weight: 400;
    font-size: 1em;
    outline: none;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.main-s-button {
    margin: 3vh auto 0 auto;
    width: 120px;
    color: #000;
    background: #fcfcfc;
    border: 1px solid #eee;
    border-radius: 18px;
    height: 40px;
    /* box-shadow: 3px 3px 1px #ddd; */
    font-weight: 400;
    font-size: 1em;
    outline: none;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.s-button-active {
    margin: 0.5vh 1vh 3vh 1vh;
    width: 120px;
    color: #000;
    background: #fcfcfc;
    border: 1px solid #9c8cf8;
    border-radius: 18px;
    height: 40px;
    /* box-shadow: 3px 3px 1px #ddd; */
    font-weight: 400;
    font-size: 1em;
    outline: none;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.s-button:focus {
    outline: none;
}

.link-text {
    font-size: 1.5em;
    color:#F0732C;
    font-weight: 500;
    text-decoration: none;
}

.result-title {
    font-size: 1em;
    color: #4AA537;
    text-decoration: none;
    text-overflow: ellipsis;
    font-weight: 500;
}

.result-card {
    margin-bottom: 20px;
}

.image-card {
    margin-bottom: 10px;
}

.logo {
    margin: 5vh auto;
}

.logo img {
    max-width: 200px;
    display: block;
    margin: auto;
}

.results {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 140px;
}

@media screen and (max-width: 768px) {
    .results {
        margin-top: 250px;
    }

    .search-container {
        width: 100%;
    }    
}

.logo-home-container {
    margin-top: 20vh;
}

.logo-home-container img {
    max-width: 270px;
}

.card-dot {
    display: block;
    margin: auto;
    height: 80px;
    width: 80px;
}

.home-footer {
    padding-top: 200px;
}

.faq {
    color: #fff;
    font-weight: bold;
    font-size: 3em;
    margin: 10px 50px;
}

.accordion-container {
    margin: 10px 50px;
}

.faq-container {
    margin: auto 200px;
}

.pagination-container {
    text-align: center;
    margin-top: 2vh;
}

.currentPage {
    color: #000 !important;
    font-weight: 500;
}

.pagination-container span {
    padding: 0 7px;
    color: #999;
    border-bottom: 1px solid #737373;
    cursor: pointer;
}

.logo-footer {
    margin: auto;
}

.logo-footer img {
    max-width: 100px;
    display: block;
    margin: auto;
}

.result-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    /* color: #4AA537; */
    font-size: 0.95em;
}

.no-results {
    font-size: 18px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.header {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 100;
}

.imageList {
    width: 100%;
    height: 100%;
}

.video-image {
    height: 180px;
    width: 320px;
    object-fit: cover;
}

.link-text-video {
    font-size: 1em;
    color:#F0732C;
    font-weight: 500;
    text-decoration: none;
}

.result-title-video {
    font-size: 0.75em;
    color: #4AA537;
    text-decoration: none;
    text-overflow: ellipsis;
    font-weight: 500;
}

.flex-container {
    display: flex;
    padding-bottom: 10px;
}

.img-container {
    transition: transform .2s; /* Animation */
    width: 200px !important;
    height: 135px !important;
    margin: 5px 5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.img-description {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* font-size: 6px; */
    background-color: #000000af;
}

.img-container:hover{
    transform: scale(2);
    border-radius: 5px;
    background-color: #000000af;
}

.img-container:hover .img-description {
    opacity: 1;
}